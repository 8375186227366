import { api } from "@/client/api";
import { configs } from "@/configs";
import { parseDateTimeFromPrisma, parseURLSearchParams } from "@/utils";

import type { Booking, Trainer } from "@/models";
import type { DateTime } from "luxon";

type GetAvailableTrainersInput = {
  start: DateTime;
  end: DateTime;
};

type CreateTrainerBookingInput = {
  trainerId: number;
  memberCode: string;
  start: DateTime;
  end: DateTime;
};

export async function getTrainers(): Promise<Trainer[]> {
  const response = await api.get(`${configs.apiUrl}/mirror/trainers`);
  return parseDateTimeFromPrisma(response.data) as Trainer[];
}

export async function getTrainer(id: number): Promise<Trainer> {
  const response = await api.get(`${configs.apiUrl}/mirror/trainers/${id}`);
  return parseDateTimeFromPrisma(response.data) as Trainer;
}

export async function getAvailableTrainers({
  start,
  end,
}: GetAvailableTrainersInput): Promise<Trainer[]> {
  const params = parseURLSearchParams({
    start: start.toISO(),
    end: end.toISO(),
  });

  const response = await api.get(
    `${configs.apiUrl}/mirror/trainers/available?${params}`
  );
  return parseDateTimeFromPrisma(response.data) as Trainer[];
}

export async function createTrainerBooking({
  trainerId,
  memberCode,
  start,
  end,
}: CreateTrainerBookingInput) {
  const response = await api.post(
    `${configs.apiUrl}/mirror/trainers/${trainerId}/bookings`,
    { startedAt: start.toISO(), endedAt: end.toISO(), memberCode }
  );
  return parseDateTimeFromPrisma(response.data) as Booking;
}
