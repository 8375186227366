import { Outlet, redirect, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";

import { useAuthentication } from "@/features/authentication/contexts/AuthenticationContext";
import ArrowLeft from "@/assets/arrow-left.png";
import Home from "@/assets/home.png";
import LayoutBG from "@/assets/layout-bg.png";
import { LandingScreen } from "./LandingScreen";

export function MainLayout() {
  const [{ isAuthenticated }] = useAuthentication();

  useEffect(() => {
    if (!isAuthenticated) {
      redirect("/auth/sign-in");
    }
    return;
  }, [isAuthenticated]);

  return (
    <Box
      height="100vh"
      width="100vw"
      overflow="auto"
      sx={{
        backgroundImage: `url(${LayoutBG})`,
        fontFamily: "Kanit",
      }}
    >
      <LandingScreen />
      <Outlet />
      <Footer />
    </Box>
  );
}

function Footer() {
  const navigate = useNavigate();

  const onClickBack = () => window.history.back();
  const onClickHome = () => navigate("/");
  return (
    <Box
      zIndex={100}
      position="fixed"
      height="182px"
      width="100%"
      bottom={0}
      borderRadius="40px 40px 0 0"
      display="flex"
      alignItems="center"
      gap={2}
      pl={12}
      sx={{
        backdropFilter: "blur(16px)",
        background: "rgba(255, 255, 255, 0.5)",
      }}
    >
      <MenuButton onClick={onClickBack} label="ย้อนกลับ" icon={ArrowLeft} />
      <MenuButton onClick={onClickHome} label="หน้าแรก" icon={Home} />
    </Box>
  );
}

type MenuButtonProps = {
  onClick: () => void;
  label: string;
  icon: string;
};

function MenuButton({ onClick, label, icon }: MenuButtonProps) {
  return (
    <Button
      variant="outlined"
      sx={{
        height: "102px",
        borderRadius: 4,
        border: "2px solid #EBEBEB",
        backgroundColor: "background.paper",
        ":hover": {
          backgroundColor: "background.paper",
          border: "2px solid #EBEBEB",
        },
        gap: 3,
        p: 3,
        fontSize: " 2rem",
        color: "text.primary",
      }}
      onClick={onClick}
      startIcon={<Box component="img" src={icon} />}
    >
      <Typography fontWeight={400} fontSize={36} color="#333333">
        {label}
      </Typography>
    </Button>
  );
}
