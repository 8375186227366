import { createSlice } from "@reduxjs/toolkit";
import { Box, CircularProgress } from "@mui/material";
import { createContext, useContext, useReducer, useEffect } from "react";
import { useSnackbar } from "notistack";

import { auth, isAxiosError, MirrorLoginResponse } from "@/services/auth";
import { getBannerUrls } from "@/services/fitnessCenter";
import { configs } from "@/configs";

import type { Dispatch, PropsWithChildren, Reducer } from "react";
import type { PayloadAction } from "@reduxjs/toolkit";
import { UserExpiredException, UserSignInExpiredException } from "@/exceptions";

export type BannerResponse = { bannerUrls: string[] };

type ErrorResponse = {
  response: {
    data: {
      message: string;
    };
    status: number;
  };
};

type AuthenticationState = {
  isInitialized: boolean;
  isAuthenticated: boolean;
  error: Error | null;
  loading: boolean;
  logoUrl: string | null;
  bannerUrls: string[];
};

const { actions, reducer, getInitialState } = createSlice({
  name: "Authentication",
  reducers: {
    initialize: (state, { payload }: PayloadAction) => {
      state.isInitialized = true;
      state.isAuthenticated = payload !== null;
    },
    initializeError: (state, { payload }: PayloadAction<Error>) => {
      state.isInitialized = true;
      state.error = payload;
    },
    setThemeConfig: (
      state,
      { payload }: PayloadAction<MirrorLoginResponse>
    ) => {
      state.logoUrl = payload.logoUrl;
      // Set theme color
      localStorage.setItem(
        "FITUP:primary.main",
        payload.theme?.primaryColor ?? "#DE2826"
      );
      localStorage.setItem(
        "FITUP:accent.main",
        payload.theme?.accentColor ?? "#A3000C"
      );
      localStorage.setItem(
        "FITUP:text.primary",
        payload.theme?.textPrimaryColor ?? "#000000DE"
      );
      localStorage.setItem(
        "FITUP:text.secondary",
        payload.theme?.textSecondaryColor ?? "#00000099"
      );
      localStorage.setItem(
        "FITUP:text.disabled",
        payload.theme?.textDisabledColor ?? "#00000061"
      );
      localStorage.setItem(
        "FITUP:background.default",
        payload.theme?.backgroundColor ?? "#F5F5F5"
      );
      localStorage.setItem(
        "FITUP:background.paper",
        payload.theme?.paperColor ?? "#FFFFFF"
      );
    },
    signInSuccess: (state, { payload }: PayloadAction<BannerResponse>) => {
      state.isAuthenticated = true;
      state.error = null;
      state.loading = false;
      state.isInitialized = true;
      state.bannerUrls = payload.bannerUrls;
    },
    signInFail: (state, { payload }: PayloadAction<Error>) => {
      state.isAuthenticated = false;
      state.error = payload;
      state.loading = false;
      state.isInitialized = true;
      state.bannerUrls = [];
    },
    signOut: (state) => {
      state.isAuthenticated = false;
      state.loading = false;
      state.bannerUrls = [];
    },
    signInRequest: (state) => {
      state.loading = true;
    },
  },
  initialState: {
    isInitialized: false,
    isAuthenticated: false,
    error: null,
    loading: false,
  } as AuthenticationState,
});

type Actions = typeof actions;
type AuthenticationActions = ReturnType<Actions[keyof Actions]>;
type AuthenticationDispatch = Dispatch<AuthenticationActions>;

const AuthenticationContext = createContext<
  readonly [AuthenticationState, AuthenticationDispatch] | null
>(null);

export function AuthenticationProvider({
  children,
}: PropsWithChildren<unknown>) {
  const [state, dispatch] = useReducer(
    reducer as Reducer<AuthenticationState, AuthenticationActions>,
    null,
    getInitialState
  );

  const { enqueueSnackbar } = useSnackbar();

  const value = [state, dispatch] as const;

  useEffect(() => {
    if (state.error) {
      const isExpireError =
        state.error instanceof UserExpiredException ||
        state.error instanceof UserSignInExpiredException;

      if (!isExpireError) {
        console.error(state.error);
        const error = state.error as unknown as ErrorResponse;
        if (error.response.status === 403) return;
        enqueueSnackbar(
          error.response.data.message ?? configs.unknownErrorMessage,
          { variant: "error" }
        );
        localStorage.removeItem("branchCode");
      }
    }
  }, [state.error, enqueueSnackbar]);

  useEffect(() => {
    if (!state.isInitialized) {
      void signIn(dispatch);
    }
  }, [state.isInitialized]);

  useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  }, []);

  return (
    <AuthenticationContext.Provider value={value}>
      {!state.isInitialized ? (
        <Box
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={500} />
        </Box>
      ) : (
        children
      )}
    </AuthenticationContext.Provider>
  );
}

export function useAuthentication() {
  const context = useContext(AuthenticationContext);

  if (!context) {
    throw new Error(
      "useAuthentication must be used inside <AuthenticationProvider />"
    );
  }

  return context;
}

export async function signIn(dispatch: AuthenticationDispatch) {
  dispatch(actions.signInRequest());
  try {
    const [fitness, banner] = await Promise.all([auth(), getBannerUrls()]);

    dispatch(actions.setThemeConfig(fitness));
    dispatch(actions.signInSuccess(banner));
  } catch (error) {
    if (isAxiosError(error)) {
      if (
        (error.response?.data as { code: string; message: string }).code ===
        "auth/expired"
      ) {
        return dispatch(actions.signInFail(new UserExpiredException()));
      }
      if (
        (error.response?.data as { code: string; message: string }).code ===
        "auth/sign-in-expired"
      ) {
        return dispatch(actions.signInFail(new UserSignInExpiredException()));
      }
      dispatch(actions.signInFail(error));
    } else {
      dispatch(
        actions.signInFail({
          response: { data: { message: "ไม่พบข้อมูล" }, status: 404 },
        } as unknown as Error)
      );
    }
  }
}
