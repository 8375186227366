import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import ErrorIcon from "@mui/icons-material/Error";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import { Fragment, useEffect, useState } from "react";

import { LoadingModal } from "@/components/LoadingModal";
import { ErrorFetching } from "@/components/ErrorFetching";
import { getClass, getSchedules } from "@/services/class";
import { useRequireParams } from "@/utils";
import { now } from "@/lib/dateTime";
import Thumbnail from "@/assets/class/thumbnail.png";
import {
  formatDate,
  formatProfile,
  formatTime,
  formatTimeRange,
} from "@/formatter";

import type { Schedule } from "@/models";

const HIDE_DESCRIPTION_LENGTH = 200;

export function ClassDetailPage() {
  const { id } = useRequireParams(["id"]);
  const [showSeeMore, setShowSeeMore] = useState(false);

  const date = now();

  const {
    data: raw,
    isFetching,
    isError,
  } = useQuery(["class-detail"], () => getClass(+id));
  const { data: schedulesRaw } = useQuery(
    ["schedules", id, date.toISODate()],
    () => getSchedules({ classId: +id, start: date })
  );

  const schedules = schedulesRaw ?? [];
  const descriptionLength = raw?.description?.length ?? 0;

  useEffect(
    () => setShowSeeMore(descriptionLength > HIDE_DESCRIPTION_LENGTH),
    [descriptionLength]
  );

  const descriptionStyle = showSeeMore
    ? {
        WebkitLineClamp: 3,
        WebkitBoxOrient: "vertical",
        display: "-webkit-box",
        overflow: "hidden",
      }
    : {};

  return (
    <Stack px={12} mt={10} mb={25} alignItems="center" gap={5}>
      {isFetching ? (
        <LoadingModal open={isFetching} />
      ) : isError || !raw ? (
        <ErrorFetching marginTop={560} />
      ) : (
        <>
          <Box
            borderRadius={4}
            component="img"
            height={480}
            width={720}
            alt={raw.name}
            src={raw.thumbnailUrls[0] ?? Thumbnail}
          />
          <Grid width="100%">
            <Typography variant="h4" sx={{ wordBreak: "break-word" }}>
              {raw.name}
            </Typography>
            <Divider
              sx={{
                backgroundColor: "#DE2826",
                height: "4px",
                my: 3,
                width: 103,
              }}
            />
            <Typography variant="body2" sx={descriptionStyle}>
              {raw.description}
            </Typography>
            {showSeeMore && (
              <Typography
                variant="body2"
                mt={1}
                color="#2C5BB0"
                sx={{ cursor: "pointer" }}
                onClick={() => setShowSeeMore(false)}
              >
                อ่านเพิ่มเติม
              </Typography>
            )}
          </Grid>
          <Typography variant="h4" width="100%">
            เลือกตารางเวลา
          </Typography>
          {schedules.length > 0 ? (
            schedules.map((schedule) => (
              <ScheduleCard data={schedule} key={schedule.id} />
            ))
          ) : (
            <Box bgcolor="#EBEBEB" p={2} width="100%">
              <Box
                bgcolor="#FFFFFF"
                width="100%"
                height={463}
                p={2}
                textAlign="center"
              >
                <EventBusyIcon
                  sx={{ fontSize: 130, color: "rgba(0, 0, 0, 0.28)", mt: 12 }}
                />
                <Typography variant="h5" color="text.disabled">
                  ไม่มีตารางเวลา
                </Typography>
              </Box>
            </Box>
          )}
        </>
      )}
    </Stack>
  );
}

type ScheduleProps = {
  data: Schedule;
};

export function ScheduleCard({ data }: ScheduleProps) {
  const { staffs, location, bookings, capacity, startedAt, endedAt, joinedAt } =
    data;

  const canBooking = joinedAt && now() < joinedAt;

  const activeBookings = bookings.filter(({ cancelledAt }) => !cancelledAt);
  const waitingBookings = bookings.filter(({ isWaiting }) => isWaiting);

  const activeCount =
    activeBookings.length >= capacity ? capacity : activeBookings.length;

  const waitingData = waitingBookings.length
    ? [{ label: "รอคิว", values: [`${waitingBookings.length} คน`] }]
    : [];

  const items = [
    {
      label: "ผู้สอน",
      values: staffs?.map(({ profile }) => formatProfile(profile)) ?? ["-"],
    },
    { label: "สถานที่", values: [location] },
    { label: "ผู้เข้าร่วม", values: [`${activeCount} / ${capacity} คน`] },
    ...waitingData,
  ];

  return (
    <Stack bgcolor="#EBEBEB" p={2} gap={2} width="100%" borderRadius={4}>
      <Grid>
        <Typography variant="h4" display="inline">
          {formatDate(startedAt)}
        </Typography>
        <Typography variant="body2" display="inline" ml={5}>
          {formatTimeRange(startedAt, endedAt)}
        </Typography>
      </Grid>
      <Box
        display="grid"
        gridTemplateColumns="136px 1fr"
        px={3}
        py={2}
        columnGap={5}
        rowGap={3}
        borderRadius={4}
        bgcolor="#FFFFFF"
      >
        {items.map(({ label, values }) => (
          <Fragment key={label}>
            <Typography variant="h5">{label}</Typography>
            <Stack gap={3}>
              {values.map((value, index) => (
                <Typography key={`${value} ${index}`} variant="body2">
                  {value}
                </Typography>
              ))}
            </Stack>
          </Fragment>
        ))}

        {canBooking && joinedAt && (
          <Typography color="error" variant="body3" gridColumn="1/-1">
            *เปิดจองวันที่ {formatDate(joinedAt)} เวลา {formatTime(joinedAt)}
          </Typography>
        )}

        {bookings.length >= capacity && (
          <Typography variant="body2" color="error.main" gridColumn="1/-1">
            <ErrorIcon color="error" sx={{ mr: 1 }} />
            ขณะนี้มีผู้เข้าร่วมเข้าครบเต็มจำนวนแล้ว หากคุณจองจะอยู่ใน
            รายการรอคิว และถ้าหาก มีผู้เข้าร่วมยกเลิก จะเรียกคิวตามลำดับ{" "}
          </Typography>
        )}
      </Box>
    </Stack>
  );
}
