import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";

import LoginBackground from "@/assets/login-bg.png";
import { LoadingModal } from "@/components/LoadingModal";

import { signIn, useAuthentication } from "../contexts/AuthenticationContext";
import { UserSignInExpiredException } from "@/exceptions";

export function SignInPage() {
  const [{ loading, error }, dispatch] = useAuthentication();
  const [branchCode, setBranchCode] = useState("");
  const [isShowExpireDialog, setIsShowExpireDialog] = useState(false);

  useEffect(() => {
    if (error instanceof UserSignInExpiredException) {
      setIsShowExpireDialog(true);
    }
  }, [error]);

  const submit = async (event: React.FormEvent) => {
    event.preventDefault();
    localStorage.setItem("branchCode", branchCode);
    await signIn(dispatch);
  };

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        backgroundImage: `url(${LoginBackground})`,
        height: "100vh",
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top right",
      }}
    >
      <Typography variant="h1" pt={25.5} pl={12}>
        ยินดีต้อนรับ
      </Typography>
      <Paper
        sx={{
          height: 1100,
          width: "100%",
          position: "fixed",
          bottom: 0,
          borderRadius: "80px 80px 0 0",
          p: 12,
        }}
      >
        <form onSubmit={submit} noValidate>
          <Stack>
            <Typography variant="h2" mb={8}>
              รหัสสาขา
            </Typography>
            <TextField
              InputProps={{
                placeholder: "กรุณากรอกรหัสสาขา",
                style: { fontSize: 36 },
                startAdornment: (
                  <InputAdornment position="start" sx={{ height: 102 }}>
                    <PersonIcon fontSize="large" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setBranchCode(e.target.value)}
            />
            <LoadingButton
              type="submit"
              variant="contained"
              loading={false}
              disabled={!branchCode}
              sx={{ mt: 65, textTransform: "none", borderRadius: 4 }}
            >
              เข้าสู่ระบบ
            </LoadingButton>
          </Stack>
        </form>
      </Paper>
      <LoadingModal open={loading} />
      <ExpireDialog
        isOpen={isShowExpireDialog}
        onClose={() => setIsShowExpireDialog(false)}
      />
    </Container>
  );
}

type ExpireDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};
function ExpireDialog({ isOpen, onClose }: ExpireDialogProps) {
  const title = "ไม่สามารถเข้าสู่ระบบได้";
  return (
    <Dialog
      open={isOpen}
      title={title}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      PaperProps={{ sx: { py: 10, px: 8, borderRadius: 4 } }}
    >
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        {title}
      </DialogTitle>
      <DialogContent sx={{ color: "#333333", pt: "48px!important", pb: 15 }}>
        <Typography variant="body1">
          เนื่องจากบัญชีของคุณไม่ได้ทำการต่ออายุ
        </Typography>
        <Typography variant="body1">กรุณาติดต่อทีมงาน FitUP</Typography>
      </DialogContent>
      <DialogActions sx={{ alignSelf: "center" }}>
        <Button
          variant="contained"
          onClick={onClose}
          sx={{ height: 100, width: 444, borderRadius: 2 }}
        >
          ตกลง
        </Button>
      </DialogActions>
    </Dialog>
  );
}
