import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";
import { useAtom } from "jotai";

import { useRequireParams } from "@/utils";
import { getVideosByCatagory } from "@/services/video";
import { LoadingModal } from "@/components/LoadingModal";
import { EmptyListData } from "@/components/EmptyListData";
import { ErrorFetching } from "@/components/ErrorFetching";
import { VideoPlayerModal } from "@/features/videos/components/VideoPlayerModal";
import { Title } from "@/components/Title";
import { isVideoModalOpenAtom } from "@/atom/global";
import Thumbnail from "@/assets/video/wide-thumbnail.svg";
import Play from "@/assets/video/play.svg";

import type { Video } from "@/models";

export function VideoListPage() {
  const [currentVideoInfo, setCurrentVideoInfo] = useState<Video | null>(null);
  const [, setVideoModalOpen] = useAtom(isVideoModalOpenAtom);

  useEffect(() => {
    setVideoModalOpen(Boolean(currentVideoInfo));
  }, [currentVideoInfo, setVideoModalOpen]);

  const { id } = useRequireParams(["id"]);
  const {
    data: raw,
    isFetching,
    isError,
  } = useQuery(["videos"], () => getVideosByCatagory(id));

  const [searchParams] = useSearchParams();
  const categoryName = searchParams.get("categoryName");

  const data = raw?.data ?? [];
  const isEmpty = data.length === 0;

  const onCloseModal = () => setCurrentVideoInfo(null);

  const handlePlayVideo = (id: number) =>
    setCurrentVideoInfo(data.find((video) => video.id === id) ?? null);

  return (
    <Stack px={12} mt={10} mb={25}>
      <Title label={categoryName ?? ""} />
      {isFetching ? (
        <LoadingModal open={isFetching} />
      ) : isError ? (
        <ErrorFetching marginTop={544} />
      ) : isEmpty ? (
        <EmptyListData marginTop={544} />
      ) : (
        <Stack mt={8} rowGap={5}>
          {data.map((item) => (
            <VideoCard key={item.id} data={item} onClick={handlePlayVideo} />
          ))}
        </Stack>
      )}
      <VideoPlayerModal
        open={!!currentVideoInfo}
        url={currentVideoInfo?.youtubeUrl ?? ""}
        onClose={onCloseModal}
        title={currentVideoInfo?.name ?? ""}
        description={currentVideoInfo?.description ?? ""}
      />
    </Stack>
  );
}

type VideoCardProps = {
  data: Video;
  onClick: (id: number) => void;
};

function VideoCard({ data, onClick }: VideoCardProps) {
  const { id, name: title, description, thumbnailUrl } = data;
  return (
    <Card
      sx={{
        position: "relative",
        height: "320px",
      }}
    >
      <CardMedia
        component="img"
        image={thumbnailUrl || Thumbnail}
        alt={title}
        height="100%"
      />
      <CardContent
        sx={{
          position: "absolute",
          display: "flex",
          alignItems: "flex-end",
          height: "180px",
          bottom: 0,
          width: "100%",
          color: "white",
          p: 3,
          background:
            "linear-gradient(180deg, rgba(39, 45, 55, 0) 0%, #272D37 100%)",
        }}
      >
        <Box flexGrow={1}>
          <Typography variant="h5" noWrap maxWidth="640px">
            {title}
          </Typography>
          <Typography variant="body2" noWrap maxWidth="640px">
            {description}
          </Typography>
        </Box>

        <Button
          variant="contained"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: "11px",
            columnGap: 3.5,
            width: "175px",
            height: "86px",
            borderRadius: "16px",
          }}
          onClick={() => onClick(id)}
        >
          <Box component="img" src={Play} />
          <Typography
            fontWeight={400}
            fontSize={36}
            color="white"
            textTransform="none"
          >
            Play
          </Typography>
        </Button>
      </CardContent>
    </Card>
  );
}
