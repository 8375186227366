import { api } from "@/client/api";
import { configs } from "@/configs";
import { Booking, Class, Schedule } from "@/models";
import {
  parseDateTimeFromPrisma,
  parseISOToPrisma,
  parseURLSearchParams,
} from "@/utils";
import { DateTime } from "luxon";

type GetClassSchedulesInput = {
  classId?: number;
  start: DateTime;
  end?: DateTime;
};

type GetDailySchedulesInput = { start: DateTime };

type CreateScheduleBookingInput = {
  memberCode: string;
  scheduleId: number;
};

type GetScheduleInput = {
  scheduleId: number;
};

export async function getClasses(): Promise<Class[]> {
  const response = await api.get(`${configs.apiUrl}/mirror/classes`);
  return parseDateTimeFromPrisma(response.data) as Class[];
}

export async function getClass(id: number): Promise<Class> {
  const response = await api.get(`${configs.apiUrl}/mirror/classes/${id}`);
  return parseDateTimeFromPrisma(response.data) as Class;
}

export async function getSchedules(
  input: GetClassSchedulesInput
): Promise<Schedule[]> {
  const params = parseISOToPrisma(input) as Record<string, string>;
  const response = await api.get(
    `${configs.apiUrl}/mirror/schedules?${parseURLSearchParams(params)}`
  );
  return parseDateTimeFromPrisma(response.data) as Schedule[];
}

export async function getDailySchedules({
  start,
}: GetDailySchedulesInput): Promise<Class[]> {
  const params = parseURLSearchParams({
    start: start.toISO(),
    end: start.endOf("day").toISO(),
  });

  const response = await api.get(
    `${configs.apiUrl}/mirror/classes/with-schedules?${params}`
  );
  return parseDateTimeFromPrisma(response.data) as Class[];
}

export async function getSchedule({
  scheduleId,
}: GetScheduleInput): Promise<Schedule> {
  const response = await api.get(
    `${configs.apiUrl}/mirror/schedules/${scheduleId}`
  );
  return parseDateTimeFromPrisma(response.data) as Schedule;
}

export async function createScheduleBooking({
  scheduleId,
  memberCode,
}: CreateScheduleBookingInput) {
  const response = await api.post(
    `${configs.apiUrl}/mirror/schedules/${scheduleId}/booking`,
    { memberCode }
  );
  return parseDateTimeFromPrisma(response.data) as Booking;
}
