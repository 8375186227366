import { Box, Stack, Typography } from "@mui/material";

import NoListData from "@/assets/no-list-data.png";

type EmptyListDataProps = { marginTop?: number };

export function EmptyListData({ marginTop = 0 }: EmptyListDataProps) {
  return (
    <Stack mt={`${marginTop}px`} width="100%" alignItems="center" gap={5}>
      <Box component="img" height="383px" src={NoListData} />
      <Typography variant="h4">ไม่มีข้อมูล</Typography>
    </Stack>
  );
}
