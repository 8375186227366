import { Box, Stack, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Logo from "@/assets/fitup-logo.png";
import LayoutBG from "@/assets/layout-bg.png";
import { SignOutButton } from "@/components/SignOutButton";
import { LandingScreen } from "@/components/LandingScreen";
import { theme } from "@/theme";
import { HexToHSL } from "@/utils";
import { useAuthentication } from "../authentication/contexts/AuthenticationContext";

const primaryHSL = HexToHSL(theme.palette.primary.main);

const buttonStyle = {
  height: 200,
  width: 545,
  borderRadius: "1rem",
  border: `6px solid hsl(${primaryHSL.h}, ${primaryHSL.s}%, 85%)`,
};

export function MainMenu() {
  const [{ logoUrl }] = useAuthentication();

  const navigate = useNavigate();

  const menu = [
    { label: "ตารางเวลา", onClick: () => navigate("/schedules") },
    { label: "คลาส", onClick: () => navigate("/classes") },
    { label: "เทรนเนอร์ส่วนตัว", onClick: () => navigate("/trainers") },
    { label: "ประเภทกิจกรรม", onClick: () => navigate("/categories") },
  ];

  return (
    <>
      <LandingScreen />
      <Stack
        width="100%"
        height="100vh"
        p={4}
        justifyContent="center"
        alignItems="center"
        spacing={8}
        gap={8}
        sx={{
          backgroundImage: `url(${LayoutBG})`,
        }}
      >
        <Box
          alt="logo"
          component="img"
          maxHeight="500px"
          width="500px"
          src={logoUrl ?? Logo}
        />
        <Stack spacing={6}>
          {menu.map(({ label, onClick }) => (
            <Button
              key={label}
              variant="contained"
              disableRipple
              sx={buttonStyle}
              onClick={onClick}
            >
              <Typography variant="button1">{label}</Typography>
            </Button>
          ))}
        </Stack>
        <SignOutButton />
      </Stack>
    </>
  );
}
