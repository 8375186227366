import {
  BookingStatus,
  Day,
  DayString,
  Member,
  Profile,
  Staff,
} from "@/models";
import { fromDurationISO } from "@/lib/duration";

import { DateTime, Duration } from "luxon";

export function formatFullDate(date: DateTime) {
  return date.setLocale("th-TH").toFormat("EEEEที่ d MMM yyyy");
}

export function formatDate(date: DateTime) {
  return date.toLocaleString(DateTime.DATE_FULL, { locale: "th" }).slice(0, -5);
}

export function formatTime(date: DateTime) {
  return date.toFormat("H:mm");
}

export function formatTimeRange(start: DateTime, end: DateTime) {
  return `${formatTime(start)} - ${formatTime(end)}`;
}

export function formatDateRange(start: DateTime, end: DateTime) {
  return `${formatDate(start)} - ${formatDate(end)}`;
}

export function formatDateTime(date: DateTime) {
  return `${formatDate(date)} ${formatTime(date)}`;
}

export function formatProfile(profile: Profile, isShortLastName = false) {
  const { firstName, lastName, nickname } = profile;

  const newLastName = isShortLastName ? `${lastName[0]}.` : lastName;
  return `${firstName} ${newLastName} (${nickname})`;
}

export function formatProfiles(items: Pick<Staff | Member, "profile">[]) {
  return items.map(({ profile }) => formatProfile(profile)).join(",");
}

const bookingStatusMapper = {
  [BookingStatus.Booked]: "จองสำเร็จ",
  [BookingStatus.Waiting]: "รอคิว",
  [BookingStatus.Cancelled]: "ยกเลิก",
  [BookingStatus.Absent]: "ขาด",
  [BookingStatus.Attended]: "มา",
};
export function formatBookingStatus(status: BookingStatus) {
  return bookingStatusMapper[status];
}

const bookingStatusColorMapper = {
  [BookingStatus.Booked]: "success.main",
  [BookingStatus.Waiting]: "warning.main",
  [BookingStatus.Cancelled]: "text.disabled",
  [BookingStatus.Absent]: "error.main",
  [BookingStatus.Attended]: "info.main",
};
export function formatBookingStatusColor(status: BookingStatus) {
  return bookingStatusColorMapper[status];
}

const reservationStatusColorMapper = {
  [BookingStatus.Booked]: "info.main",
  [BookingStatus.Waiting]: "warning.main",
  [BookingStatus.Cancelled]: "text.disabled",
  [BookingStatus.Absent]: "error.main",
  [BookingStatus.Attended]: "success.main",
};
export function formatReservationStatusColor(status: BookingStatus) {
  return reservationStatusColorMapper[status];
}

const dayMapper = {
  [Day.Monday]: "จันทร์",
  [Day.Tuesday]: "อังคาร",
  [Day.Wednesday]: "พุธ",
  [Day.Thursday]: "พฤหัสบดี",
  [Day.Friday]: "ศุกร์",
  [Day.Saturday]: "เสาร์",
  [Day.Sunday]: "อาทิตย์",
};
const dayStringMapper = {
  mon: "Monday",
  tue: "Tuesday",
  wed: "Wednesday",
  thu: "Thursday",
  fri: "Friday",
  sat: "Saturday",
  sun: "Sunday",
};
export function formatDayString(day: DayString) {
  return dayStringMapper[day] as Day;
}

const dayWeight = Object.keys(dayMapper);
export function formatDay(day: Day) {
  return dayMapper[day];
}
export function sortDay(d1: Day, d2: Day) {
  return dayWeight.indexOf(d1) - dayWeight.indexOf(d2);
}

function formatDuration(duration: Duration): string {
  return duration.reconfigure({ locale: "th-TH" }).toHuman();
}

export function formatDurationISO(iso: string): string {
  return formatDuration(fromDurationISO(iso));
}
