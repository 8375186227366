import { Box, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";

import { LoadingModal } from "@/components/LoadingModal";
import { ErrorFetching } from "@/components/ErrorFetching";
import { EmptyListData } from "@/components/EmptyListData";
import { Carousel } from "@/components/Carousel";
import { useAuthentication } from "@/features/authentication/contexts/AuthenticationContext";
import { getDailySchedules } from "@/services/class";
import { fromISO, now } from "@/lib/dateTime";
import { formatTime } from "@/formatter";
import DefaultBanner from "@/assets/schedule/default-banner.svg";
import DefaultClassThumbnail from "@/assets/class/thumbnail.png";

import { ScheduleDialog } from "./ScheduleDialog";

import type { DateTime } from "luxon";
import type { Class, Schedule } from "@/models";

export function ScheduleListPage() {
  const [{ bannerUrls }] = useAuthentication();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isScrollToTop, setIsScrollToTop] = useState(true);

  const date = searchParams.get("date");
  const start = date ? fromISO(date) : now().startOf("day");

  const scheduleId = searchParams.get("scheduleId");

  const dialog = {
    isOpen: !!scheduleId,
    onClose: () => {
      searchParams.delete("scheduleId");
      setSearchParams(searchParams, { replace: true });
    },
    scheduleId: scheduleId ? +scheduleId : undefined,
  };

  const {
    data: raw,
    isFetching,
    isError,
  } = useQuery(["dailySchedules", start.toISODate()], () =>
    getDailySchedules({ start })
  );

  const data = raw ?? [];
  const today = now();
  const dateList = Array.from(Array(7).keys()).map((index) =>
    today.plus({ day: index })
  );

  const thumbnails = bannerUrls.length ? bannerUrls : [DefaultBanner];
  const isEmpty = data.length === 0;

  const inactiveClasses: Class[] = [];
  const activeClasses: Class[] = [];

  data.forEach((classData) => {
    const { schedules } = classData;
    const isActiveClass = schedules.some(({ startedAt }) => now() < startedAt);
    isActiveClass
      ? activeClasses.push(classData)
      : inactiveClasses.push(classData);
  });

  const boxShadow = isScrollToTop
    ? { boxShadow: "0px 10px 24px 0px #C3CAD540" }
    : {};

  return (
    <Stack
      height="100vh"
      overflow="scroll"
      onScroll={(event) => setIsScrollToTop(event.currentTarget.scrollTop > 0)}
    >
      <Stack position="fixed" bgcolor="background.paper" {...boxShadow}>
        <Box p={5} pt={10}>
          <Carousel
            imageUrls={thumbnails}
            imgSx={{ height: 360, borderRadius: 4 }}
          />
        </Box>
        <Box display="grid" gridTemplateColumns="repeat(7, 1fr)">
          {dateList.map((date) => (
            <DaySelector key={date.toISODate()} date={date} selected={start} />
          ))}
        </Box>
      </Stack>
      <Stack mt={85}>
        {isFetching ? (
          <LoadingModal open={isFetching} />
        ) : isError ? (
          <ErrorFetching marginTop={216} />
        ) : isEmpty ? (
          <EmptyListData marginTop={280} />
        ) : (
          <Stack px={7} pb={31} gap={7}>
            {activeClasses.map((item) => (
              <ClassItem key={item.id} data={item} />
            ))}
            {inactiveClasses.map((item) => (
              <ClassItem key={item.id} data={item} />
            ))}
          </Stack>
        )}
      </Stack>
      <ScheduleDialog {...dialog} />
    </Stack>
  );
}

type DaySelectorProps = { date: DateTime; selected: DateTime };

function DaySelector({ date, selected }: DaySelectorProps) {
  const dateISO = date.toISODate();
  const isToday = dateISO === now().toISODate();
  const isSelected = dateISO === selected.toISODate();

  const [searchParams, setSearchParams] = useSearchParams();

  const dayName = isToday
    ? "วันนี้"
    : date.toLocaleString({ weekday: "short" }, { locale: "TH" });

  const color = isSelected ? "primary.main" : "text.primary";

  const onClick = () => {
    searchParams.set("date", date.toISODate());
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <Stack justifyContent="center" alignItems="center" p={3} onClick={onClick}>
      <Typography variant="h6" color={color}>
        {dayName}
      </Typography>
      <Typography variant="h5" color={color}>
        {date.day}
      </Typography>
    </Stack>
  );
}

type ClassItemProps = { data: Class };
function ClassItem({ data }: ClassItemProps) {
  const { thumbnailUrls, name, schedules } = data;

  const thumbnail = thumbnailUrls[0] ?? DefaultClassThumbnail;

  const pastSchedules: Schedule[] = [];
  const futureSchedules: Schedule[] = [];

  schedules.forEach((data) => {
    const isPast = now() >= data.startedAt;
    isPast ? pastSchedules.push(data) : futureSchedules.push(data);
  });

  return (
    <Stack direction="row" gap={8}>
      <Box
        component="img"
        src={thumbnail}
        alt="class-image"
        width={272}
        height={184}
        borderRadius={4}
      />
      <Stack gap={3} width={634}>
        <Typography noWrap variant="h4">
          {name}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns="repeat(4, 128px)"
          columnGap={2}
          rowGap={3}
        >
          {pastSchedules.map((schedule) => (
            <ScheduleSelector key={schedule.id} data={schedule} status="past" />
          ))}
          {futureSchedules.map((schedule, index) => (
            <ScheduleSelector
              key={schedule.id}
              data={schedule}
              status={index === 0 ? "next" : "future"}
            />
          ))}
        </Box>
      </Stack>
    </Stack>
  );
}

type ScheduleSelectorProps = {
  data: Schedule;
  status: "past" | "next" | "future";
};

function ScheduleSelector({ data, status }: ScheduleSelectorProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { id, startedAt } = data;

  const configMapper = {
    past: {
      container: { bgcolor: "#EBEBEB", border: "none" },
      text: { color: "#ADADAD" },
    },
    next: {
      container: { borderColor: "primary.main" },
      text: { color: "primary.main" },
    },
    future: {
      container: { borderColor: "text.secondary" },
      text: { color: "text.secondary" },
    },
  };

  const { container, text } = configMapper[status];
  const onClick = ["next", "future"].includes(status)
    ? {
        onClick: () => {
          searchParams.set("scheduleId", id.toString());
          setSearchParams(searchParams, { replace: true });
        },
      }
    : {};

  return (
    <Stack
      width={128}
      height={72}
      justifyContent="center"
      alignItems="center"
      borderRadius={4}
      border="1px solid"
      {...onClick}
      {...container}
    >
      <Typography variant="h6" {...text}>
        {formatTime(startedAt)}
      </Typography>
    </Stack>
  );
}
