import { Box, Grid, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { EmptyListData } from "@/components/EmptyListData";
import { ErrorFetching } from "@/components/ErrorFetching";
import { getCategories } from "@/services/video";
import { LoadingModal } from "@/components/LoadingModal";
import { Title } from "@/components/Title";
import Thumbnail from "@/assets/video/video-thumbnail.svg";

import type { CatergoryData } from "@/models";
import type { MouseEventHandler } from "react";

export function CategoryListPage() {
  const title = "ประเภทกิจกรรม";

  const {
    data: raw,
    isFetching,
    isError,
  } = useQuery(["categories"], () => getCategories());

  const data = raw?.data ?? [];
  const isEmpty = data.length === 0;

  return (
    <Stack px={12} mt={10} mb={25}>
      <Title label={title} />
      {isFetching ? (
        <LoadingModal open={isFetching} />
      ) : isError ? (
        <ErrorFetching marginTop={544} />
      ) : isEmpty ? (
        <EmptyListData marginTop={544} />
      ) : (
        <Grid container spacing={5} mt={3}>
          {data.map((item) => (
            <CategoryCard data={item} key={item.id} />
          ))}
        </Grid>
      )}
    </Stack>
  );
}

type CategoryCardProps = {
  data: CatergoryData;
};

export function CategoryCard({ data }: CategoryCardProps) {
  const { id, thumbnailUrl, name } = data;
  const navigate = useNavigate();

  const onClick: MouseEventHandler<HTMLDivElement> = () => {
    navigate(`${id}/videos?categoryName=${name}`);
  };

  return (
    <Grid
      item
      xs={6}
      overflow="hidden"
      height={360}
      width={424}
      borderRadius={3}
      onClick={onClick}
    >
      <Box
        borderRadius={6}
        height={320}
        width={424}
        sx={{
          backgroundImage: `url(${thumbnailUrl ?? Thumbnail})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        display="flex"
        alignItems="flex-end"
        position="relative"
      >
        <Box
          width="100%"
          height={126}
          position="absolute"
          borderRadius="0 0 24px 24px"
          sx={{
            background:
              "linear-gradient(180deg, rgba(39, 45, 55, 0) 0%, #3B4454 100%)",
          }}
        />
        <Typography
          color="white"
          variant="h5"
          m={3}
          position="absolute"
          overflow="hidden"
          textOverflow="ellipsis"
          sx={{
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
          }}
        >
          {name}
        </Typography>
      </Box>
    </Grid>
  );
}
