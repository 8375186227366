import { Box, Stack, Typography } from "@mui/material";

import Error from "@/assets/error.svg";

type ErrorFetchingProps = {
  marginTop?: number;
};
export function ErrorFetching({ marginTop = 0 }: ErrorFetchingProps) {
  return (
    <Stack
      height={624}
      width="100%"
      marginTop={`${marginTop}px`}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      <Box component="img" height="383px" src={Error} mb={5} />
      <Typography variant="h4" mb={3}>
        ขออภัย
        <br />
        เกิดข้อผิดพลาดบางประการ
      </Typography>
      <Typography variant="h5" color="#ADADAD">
        กรุณาลองใหม่อีกครั้ง
      </Typography>
    </Stack>
  );
}
