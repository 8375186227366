import { DateTime } from "luxon";
import { useParams } from "react-router-dom";

import { formatDayString } from "@/formatter";

import { fromISO, fromJSDate } from "./lib/dateTime";

import type { DayString, OfficeDay, WorkingDays } from "@/models";
import type { MutableRefObject, Ref } from "react";

// TODO refactor
export function parseDateTimeFromPrisma(input: unknown): unknown {
  if (typeof input === "string" && isNaN(+input) && fromISO(input).isValid) {
    return fromISO(input);
  }
  if (input instanceof Date) {
    return fromJSDate(input);
  }

  if (Array.isArray(input) && input !== null) {
    return input.map((item: unknown) => parseDateTimeFromPrisma(item));
  }

  if (input instanceof Object) {
    const data: Record<string, unknown> = {};
    Object.keys(input).forEach((key) => {
      const newInput = input as Record<string, unknown>;
      data[key] = parseDateTimeFromPrisma(newInput[key]);
    });
    return data;
  }

  return input;
}

// TODO refactor
export function parseISOToPrisma(input: unknown): unknown {
  if (input instanceof Date) {
    return fromJSDate(input).toISO();
  }

  if (input instanceof DateTime) {
    return input.toISO();
  }

  if (Array.isArray(input) && input !== null) {
    return input.map((item: unknown) => parseISOToPrisma(item));
  }

  if (input instanceof Object) {
    const data: Record<string, unknown> = {};
    Object.keys(input).forEach((key) => {
      const newInput = input as Record<string, unknown>;
      data[key] = parseISOToPrisma(newInput[key]);
    });
    return data;
  }

  return input;
}

export function useRequireParams<Key extends string | number | symbol = string>(
  keys: Key[]
): Record<Key, string> {
  const params = useParams();

  if (!keys.every((key) => key in params)) {
    throw new Error("Input key not in params");
  }

  return params as unknown as Record<Key, string>;
}

export function getOfficeDay(workingDays: WorkingDays) {
  const officeDay = [];
  for (const workingDay in workingDays) {
    const day = workingDay as DayString;
    const enable = !!workingDays[day].startTime;
    const map: OfficeDay = {
      enable,
      day: formatDayString(day),
      timeRange: enable
        ? {
            start: fromISO(workingDays[day].startTime.toString()),
            end: fromISO(workingDays[day].endTime.toString()),
          }
        : null,
    };
    officeDay.push(map);
  }
  return officeDay;
}

export function getNextHalfTime(time: DateTime): DateTime {
  const newTime = time.startOf("minute");
  const minuteOffset = 30 - newTime.minute;
  return newTime.plus({ minute: minuteOffset });
}

export function setTime(date: DateTime, time: string) {
  const [hour, minute] = time.split(":");
  return date.set({ hour: +hour, minute: +minute }).startOf("minute");
}

export function parseURLSearchParams(object: Record<string, string>): string {
  return new URLSearchParams(object).toString();
}

export function mergeRefs<T>(...refs: Array<Ref<T> | undefined>) {
  return (node: T) => {
    refs.forEach((ref) => {
      if (!ref) return;
      if (typeof ref === "object") {
        (ref as MutableRefObject<T>).current = node;
        return;
      }
      ref(node);
    });
  };
}

type GetTimeListInput = {
  start: DateTime;
  end: DateTime;
};

export function getTimeList(timeRange?: GetTimeListInput) {
  const hours = Array.from({ length: 24 }).map((_, index) => index);
  const minutes = ["00", "30"];

  const newHours = timeRange
    ? hours.filter(
        (hour) => hour >= timeRange.start.hour && hour <= timeRange.end.hour
      )
    : hours;

  const timeList = newHours.flatMap((hour) =>
    minutes.map((minute) => `${hour}:${minute}`)
  );

  if (timeRange?.start.minute === 30) {
    timeList.splice(0, 1);
  }

  if (timeRange?.end.minute === 0) {
    timeList.pop();
  }

  return timeList;
}

export function HexToHSL(hex: string): { h: number; s: number; l: number } {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) {
    throw new Error("Could not parse Hex Color");
  }

  const rHex = parseInt(result[1], 16);
  const gHex = parseInt(result[2], 16);
  const bHex = parseInt(result[3], 16);

  const r = rHex / 255;
  const g = gHex / 255;
  const b = bHex / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h = (max + min) / 2;
  let s = h;
  let l = h;

  if (max === min) {
    // Achromatic
    return { h: 0, s: 0, l };
  }

  const d = max - min;
  s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
  switch (max) {
    case r:
      h = (g - b) / d + (g < b ? 6 : 0);
      break;
    case g:
      h = (b - r) / d + 2;
      break;
    case b:
      h = (r - g) / d + 4;
      break;
  }
  h /= 6;

  s = s * 100;
  s = Math.round(s);
  l = l * 100;
  l = Math.round(l);
  h = Math.round(360 * h);

  return { h, s, l };
}

export function signOut() {
  localStorage.setItem("branchCode", "");
  // clear theme color
  localStorage.removeItem("FITUP:primary.main");
  localStorage.removeItem("FITUP:accent.main");
  localStorage.removeItem("FITUP:text.primary");
  localStorage.removeItem("FITUP:text.secondary");
  localStorage.removeItem("FITUP:text.disabled");
  localStorage.removeItem("FITUP:background.default");
  localStorage.removeItem("FITUP:background.paper");
  window.location.reload();
}
