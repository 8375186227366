import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";

export function LandingScreen() {
  const timeout = 1000 * 60 * 3; // 3 mins
  const navigate = useNavigate();
  const [showLanding, setShowLanding] = useState(false);

  const onIdle = () => setShowLanding(true);
  const onActive = () => {
    setShowLanding(false);
  };

  useIdleTimer({
    onIdle,
    onActive,
    timeout,
    crossTab: true,
    syncTimers: 200,
    name: "activity-timer",
  });

  useEffect(() => {
    if (showLanding) navigate("schedules");
  }, [navigate, showLanding]);

  return <></>;
}
