const env = import.meta.env.VITE_ENV ?? "development";
const apiUrl = import.meta.env.VITE_REST_URL;

const buildNumber = import.meta.env.VITE_BUILD_NUMBER ?? "localhost";
const appVersion = import.meta.env.VITE_VERSION ?? "";

const version = appVersion ? `v${appVersion.split("-v")[1]}` : "localhost";
const limitPerPage = 20;
const timezone = "Asia/Bangkok";
const unknownErrorMessage = "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง";
const defaultImageURL = "/default-image.svg";
const sentryDSN = import.meta.env.VITE_SENTRY_DSN;

export const configs = {
  env,
  apiUrl,
  version,
  buildNumber,
  limitPerPage,
  timezone,
  unknownErrorMessage,
  defaultImageURL,
  sentryDSN,
};
