import { api } from "@/client/api";
import { configs } from "@/configs";
import { CatergoryData, Video } from "@/models";

type GetCategories = {
  data: CatergoryData[];
  total: number;
};

type GetVideos = {
  data: Video[];
  total: number;
};

export async function getCategories() {
  const { data } = await api.get<GetCategories>(
    `${configs.apiUrl}/mirror/categories`
  );
  return data;
}

export async function getVideosByCatagory(categoryId: string) {
  const { data } = await api.get<GetVideos>(
    `${configs.apiUrl}/mirror/videos?category=${categoryId}`
  );
  return data;
}
