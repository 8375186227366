import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Fragment } from "react";
import { useQuery } from "@tanstack/react-query";
import CloseIcon from "@mui/icons-material/Close";

import { formatDate, formatProfile, formatTimeRange } from "@/formatter";
import { getSchedule } from "@/services/class";
import DefaultClassThumbnail from "@/assets/class/thumbnail.png";

type ScheduleDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  scheduleId?: number;
};
export function ScheduleDialog({
  isOpen,
  onClose,
  scheduleId,
}: ScheduleDialogProps) {
  const { data, isFetching } = useQuery(
    ["schedule", scheduleId],
    () => getSchedule({ scheduleId: scheduleId ?? -1 }),
    { enabled: !!scheduleId }
  );

  const close = () => onClose();

  const thumbnail = data?.class?.thumbnailUrls[0] ?? DefaultClassThumbnail;

  const bookings = data?.bookings ?? [];
  const capacity = data?.capacity ?? 0;
  const staffs = data?.staffs ?? [];

  const activeBookings = bookings.filter(({ cancelledAt }) => !cancelledAt);
  const waitingBookings = bookings.filter(({ isWaiting }) => isWaiting);

  const activeCount =
    activeBookings.length >= capacity ? capacity : activeBookings.length;

  const waitingData = waitingBookings.length
    ? [{ label: "รอคิว", values: [`${waitingBookings.length} คน`] }]
    : [];

  const items = [
    {
      label: "ผู้สอน",
      values: staffs.map(({ profile }) => formatProfile(profile)),
    },
    {
      label: "สถานที่",
      values: [data?.location ?? ""],
    },
    { label: "ผู้เข้าร่วม", values: [`${activeCount}/${capacity} คน`] },
    ...waitingData,
  ];

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      fullWidth
      maxWidth="lg"
      PaperProps={{ sx: { mx: 8, borderRadius: 4 } }}
    >
      <DialogTitle display="flex" justifyContent="flex-end" sx={{ p: 5 }}>
        <IconButton onClick={close}>
          <CloseIcon sx={{ width: 48, height: 48, color: "text.primary" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 10 }}>
        {isFetching || !data ? (
          <Stack height={800} justifyContent="center" alignItems="center">
            <CircularProgress size={200} />
          </Stack>
        ) : (
          <Stack>
            <Typography
              noWrap
              variant="h4"
              textAlign="center"
              width={888}
              mb={4}
            >
              {data?.class?.name}
            </Typography>
            <Box
              component="img"
              src={thumbnail}
              alt="class-image"
              width="100%"
              height={584}
              borderRadius={4}
              mb={7}
            />
            <Typography variant="h4" mb={2}>
              {formatDate(data.startedAt)}{" "}
              {formatTimeRange(data.startedAt, data.endedAt)}
            </Typography>
            <Divider
              sx={{ bgcolor: "primary.main", height: 4, width: 120, mb: 6 }}
            />
            <Box
              display="grid"
              gridTemplateColumns="136px 1fr"
              columnGap={5}
              rowGap={3}
            >
              {items.map(({ label, values }) => (
                <Fragment key={label}>
                  <Typography variant="body2" color="#999999">
                    {label}
                  </Typography>
                  <Stack gap={3}>
                    {values.map((value, index) => (
                      <Typography
                        key={`${value} ${index}`}
                        variant="body2"
                        color="#333333"
                      >
                        {value}
                      </Typography>
                    ))}
                  </Stack>
                </Fragment>
              ))}
            </Box>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}
