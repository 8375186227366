import { Box, CircularProgress, Modal } from "@mui/material";

type LoadingModalProps = {
  open: boolean;
  onClose?: () => void;
};

export function LoadingModal({ open, onClose }: LoadingModalProps) {
  return (
    <Modal open={open} onClose={onClose} sx={{ alignItems: "center" }}>
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress size={500} />
      </Box>
    </Modal>
  );
}
