import { api } from "@/client/api";
import { configs } from "@/configs";
import { AxiosError } from "axios";

export interface MirrorLoginResponse {
  branches: Branch[];
  logoUrl: string;
  theme: Theme;
}

export interface Branch {
  id: number;
  name: string;
  code: string;
  address: string;
  district: string;
  email: string;
  googleMapLink: string;
  phoneNo: string;
  postCode: string;
  province: string;
  subdistrict: string;
}

export interface Theme {
  id: number;
  fitnessCentersId: number;
  primaryColor: string | null;
  backgroundColor: string | null;
  accentColor: string | null;
  paperColor: string | null;
  textDisabledColor: string | null;
  textPrimaryColor: string | null;
  textSecondaryColor: string | null;
}

export async function auth(): Promise<MirrorLoginResponse> {
  const response = await api.post(`${configs.apiUrl}/auth/mirror-login`);
  return response.data as MirrorLoginResponse;
}

export function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError).name === "AxiosError";
}
