import {
  Card,
  CardContent,
  CardMedia,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";

import {
  formatDay,
  formatProfile,
  formatTimeRange,
  sortDay,
} from "@/formatter";
import { getOfficeDay } from "@/utils";
import NoAvatar from "@/assets/trainer/no-avatar.png";

import type { Trainer } from "@/models";

type TrainerDetailCardProps = {
  data?: Trainer;
};

export function TrainerDetailCard({ data }: TrainerDetailCardProps) {
  if (!data) return <></>;

  const {
    id,
    trainerProfileData: { avatarUrl, skills, workingDays, certificates },
    profile,
  } = data;

  const officeDay = getOfficeDay(workingDays).filter(({ enable }) => enable);

  return (
    <Card>
      <CardMedia
        loading="eager"
        component="img"
        image={avatarUrl ?? NoAvatar}
        height={1328}
      />
      <CardContent sx={{ p: "40px !important", bgcolor: "#EBEBEB33" }}>
        <Stack gap={2}>
          <Typography variant="h4">{formatProfile(profile)}</Typography>
          <Stack direction="row" gap={10}>
            <MilitaryTechIcon sx={{ color: "text.primary", fontSize: 60 }} />
            <Stack>
              {skills.map((skill) => (
                <Typography key={skill} variant="body1" display="list-item">
                  {skill}
                </Typography>
              ))}
            </Stack>
          </Stack>
          <Divider />
          <Stack gap={0.5}>
            <Typography variant="h4" mb={0.5}>
              วันและเวลาเข้างาน
            </Typography>
            {officeDay
              .sort(({ day: d1 }, { day: d2 }) => sortDay(d1, d2))
              .map((day) => (
                <Stack direction="row" key={`${id}-${day.day}`}>
                  <Typography variant="body1" width={227}>
                    {formatDay(day.day)}
                  </Typography>
                  <Typography variant="body1">
                    {day.timeRange &&
                      formatTimeRange(day.timeRange.start, day.timeRange.end)}
                  </Typography>
                </Stack>
              ))}
          </Stack>
          {!!certificates.length && (
            <Stack mt={1}>
              <Typography variant="h4" mb={1}>
                ใบรับรอง
              </Typography>
              {certificates.map((certificate) => (
                <Typography
                  key={certificate}
                  variant="body1"
                  ml={8}
                  display="list-item"
                >
                  {certificate}
                </Typography>
              ))}
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
