import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import {
  CheckCircleOutline,
  ErrorOutline,
  ReportProblemOutlined,
} from "@mui/icons-material";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers";

import { theme } from "@/theme";
import { AuthenticationProvider } from "@/features/authentication/contexts/AuthenticationContext";
import { NonAuthenticatedGuard } from "@/features/authentication/guards/NonAuthenticatedGuard";
import { AuthenticatedGuard } from "@/features/authentication/guards/AuthenticatedGuard";
import { SignInPage } from "@/features/authentication/pages/SignInPage";
import { MainMenu } from "@/features/main-menu/MainMenu";
import { MainLayout } from "@/components/MainLayout";
import { TrainerListPage } from "@/features/trainer/pages/TrainerListPage/TrainerListPage";
import { TrainerDetailPage } from "@/features/trainer/pages/TrainerDetailPage/TrainerDetailPage";
import { VideoListPage } from "@/features/videos/pages/VideoListPage/VideoListPage";
import { ScheduleListPage } from "@/features/schedule/pages/ScheduleListPage/ScheduleListPage";

import { CategoryListPage } from "./features/videos/pages/CategoryListPage/CategoryListPage";
import { ClassListPage } from "./features/class/pages/ClassListPage/ClassListPage";
import { ClassDetailPage } from "./features/class/pages/ClassDetailPage/ClassDetailPage";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const react = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

react.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            autoHideDuration={1500}
            iconVariant={{
              success: <CheckCircleOutline />,
              error: <ErrorOutline />,
              warning: <ReportProblemOutlined />,
              info: <ErrorOutline />,
            }}
          >
            <QueryClientProvider client={queryClient}>
              <AuthenticationProvider>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <Helmet>
                    <meta
                      name="viewport"
                      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                    />
                  </Helmet>
                  <Routes>
                    <Route
                      path="/auth/sign-in"
                      element={
                        <NonAuthenticatedGuard>
                          <SignInPage />
                        </NonAuthenticatedGuard>
                      }
                    />
                    <Route
                      path=""
                      element={<AuthenticatedGuard children={<Outlet />} />}
                    >
                      <Route index element={<MainMenu />} />
                      <Route path="" element={<MainLayout />}>
                        <Route path="schedules">
                          <Route index element={<ScheduleListPage />} />
                        </Route>
                        <Route path="classes">
                          <Route index element={<ClassListPage />} />
                          <Route path=":id" element={<ClassDetailPage />} />
                        </Route>
                        <Route path="trainers">
                          <Route index element={<TrainerListPage />} />
                          <Route path=":id" element={<TrainerDetailPage />} />
                        </Route>
                        <Route path="categories">
                          <Route index element={<CategoryListPage />} />
                          <Route
                            path=":id/videos"
                            element={<VideoListPage />}
                          />
                        </Route>
                      </Route>
                    </Route>
                  </Routes>
                </LocalizationProvider>
              </AuthenticationProvider>
            </QueryClientProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);
