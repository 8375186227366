import { createTheme, PaletteOptions } from "@mui/material";
import React from "react";
declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    button1: React.CSSProperties;
    button2: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    button1?: React.CSSProperties;
    button2?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
    button1: true;
    button2: true;
  }
}

const palette: Partial<PaletteOptions> = {
  primary: {
    main: localStorage.getItem("FITUP:primary.main") ?? "#DE2826",
    dark: localStorage.getItem("FITUP:accent.main") ?? "#A3000C",
    light: "#DC8687",
  },
  secondary: {
    main: "#B90810",
    dark: "#710008",
    light: "#B9706F",
  },
  success: {
    main: "#2E7D32",
    dark: "#1B5E20",
  },
  error: {
    main: "#E83639",
    dark: "#9A352F",
    light: "#B27574",
  },
  text: {
    primary: localStorage.getItem("FITUP:text.primary") ?? "#000000DE",
    secondary: localStorage.getItem("FITUP:text.secondary") ?? "#00000099",
    disabled: localStorage.getItem("FITUP:text.disabled") ?? "#00000061",
  },
  background: {
    default: localStorage.getItem("FITUP:background.default") ?? "#F5F5F5",
    paper: localStorage.getItem("FITUP:background.paper") ?? "#FFFFFF",
  },
  info: {
    main: "#0288D1",
    dark: "#0039AC",
  },
  warning: {
    main: "#ED6C02",
    dark: "#FE9F0B",
  },
  action: {
    active: "rgba(0, 0, 0, 0.28)",
    activatedOpacity: 0.28,
    hoverOpacity: 0.04,
    selectedOpacity: 0.8,
    disabledOpacity: 0.26,
    focusOpacity: 0.12,
  },
};

export const theme = createTheme({
  palette,
  typography: {
    fontWeightRegular: 500,
    fontFamily: "Kanit, Arial, sans-serif",
    h1: {
      fontWeight: 500,
      fontSize: "6rem",
    },
    h2: {
      fontWeight: 500,
      fontSize: "4.5rem",
    },
    h3: {
      fontWeight: 500,
      fontSize: "4rem",
    },
    h4: {
      fontWeight: 500,
      fontSize: "2.75rem",
    },
    h5: {
      fontWeight: 500,
      fontSize: "2.25rem",
    },
    h6: {
      fontWeight: 500,
      fontSize: "1.5rem",
    },
    body1: {
      fontWeight: 300,
      fontSize: "3rem",
    },
    body2: {
      fontWeight: 300,
      fontSize: "2.25rem",
    },
    body3: {
      fontWeight: 300,
      fontSize: "1.75rem",
    },
    body4: {
      fontWeight: 300,
      fontSize: "1.5rem",
    },
    button1: {
      fontWeight: 400,
      fontSize: "3rem",
    },
    button2: {
      fontWeight: 400,
      fontSize: "2.25rem",
    },
  },
});

theme.components = {
  MuiCssBaseline: {
    styleOverrides: `
    @font-face {
      font-family: 'Kanit';
      font-style: normal;
      font-display: swap;
      font-weight: 300;
      src: local('Kanit'), local('Kanit-Light'), url('/fonts/Kanit-Light.woff2') format('woff2');
    }

    @font-face {
      font-family: 'Kanit';
      font-style: normal;
      font-display: swap;
      font-weight: 400;
      src: local('Kanit'), local('Kanit-Regular'), url('/fonts/Kanit-Regular.woff2') format('woff2');
    }

    @font-face {
      font-family: 'Kanit';
      font-style: normal;
      font-display: swap;
      font-weight: 500;
      src: local('Kanit'), local('Kanit-Medium'), url('/fonts/Kanit-Medium.woff2') format('woff2');
    }

      background: ${theme.palette.background.default};
      
      .SnackbarItem-contentRoot {
        border-radius: ${theme.shape.borderRadius}px !important;
      }
      
      .SnackbarItem-variantSuccess {
        background-color: ${theme.palette.success.main} !important;
      }
      
      .SnackbarItem-variantError {
        background-color: ${theme.palette.error.main} !important;
      }
      
      .SnackbarItem-message > .MuiSvgIcon-root {
        margin-right: ${theme.spacing(2)};
        font-size: 36px;
      }

      .SnackbarItem-message {
        font-size: 36px
      }
      
      .MuiPickersToolbar-root.MuiDatePickerToolbar-root {
        margin-bottom: 32px;
        gap: 32px;
        padding: 0;
        
        .MuiPickersToolbar-content {
          width: 100%;
        }
        
        .MuiTypography-root {
          font-weight: 300;
          font-size: 1.5rem;
        }
    
        .MuiTypography-h4 {
            font-weight: 500;
            font-size: 4.5rem;
        }
      
        .MuiSvgIcon-root {
            display: none;
        }
      }

      .MuiDialogContent-root div div:last-child {
        max-height: unset;
        width: unset;
        
        .MuiCalendarPicker-root {
          width: 680px !important;
          
          div {
            padding: 0;
          }
          
          [role=presentation] {
            padding: 0;
            max-height: unset;
            width: auto;
            font-weight: 300;
            font-size: 2.25rem;
          }
          
          .MuiSvgIcon-root {
            font-size: 60px;
          }
    
          [role=grid] {
            margin-top: 32px; 
            
            .MuiTypography-root {
              width: 96px;
              height: 96px;
    
              font-weight: 300;
              font-size: 1.75rem;
            }
    
            .MuiPickersDay-root {
              width: 96px;
              height: 96px;
    
              font-weight: 400;
              font-size: 2rem;
            }
          }
        }
      
        .MuiYearPicker-root {
          max-height: 358px;
          row-gap: 40px;
          margin-top: 40px;

          button {
            font-weight: 400;
            font-size: 1.75rem;
            line-height: 0;
      
            &.Mui-selected {
              border-radius: 8px;
            }

            &.Mui-disabled {
              color: ${theme.palette.text.disabled}
            }
          }
        }
      }
      
      .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar1Determinate {
        background-color: #2C5BB0;
      }
    `,
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: 24,
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontSize: "1.5rem",
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        fontSize: "2.25rem",
        borderRadius: "12px !important",
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        height: 120,
        fontSize: "3rem !important",
        boxShadow: "none",
      },
      outlined: {
        ":hover": {
          backgroundColor: theme.palette.background.default,
        },
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        ...theme.typography.h3,
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        gap: 20,

        ".MuiButtonBase-root.MuiButton-root.MuiButton-text": {
          width: 188,
          height: 88,
          fontWeight: 400,
          fontSize: "2.25rem !important",
        },
      },
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.text.primary,
      },
    },
  },
};
