import { Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";

type TitleProps = {
  label: string;
};

export function Title({ label }: TitleProps) {
  return (
    <>
      <Helmet>
        <title>{label}</title>
      </Helmet>
      <Typography variant="h3" alignSelf="center">
        {label}
      </Typography>
    </>
  );
}
