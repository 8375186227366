import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { EmptyListData } from "@/components/EmptyListData";
import { ErrorFetching } from "@/components/ErrorFetching";
import { LoadingModal } from "@/components/LoadingModal";
import { getTrainers } from "@/services/trainer";
import { formatProfile } from "@/formatter";
import { Title } from "@/components/Title";
import NoAvatar from "@/assets/trainer/no-avatar.png";

import type { Trainer } from "@/models";
import type { MouseEventHandler } from "react";

export function TrainerListPage() {
  const title = "เทรนเนอร์ทั้งหมดของเรา";

  const {
    data: raw,
    isFetching,
    isError,
  } = useQuery(["trainers"], () => getTrainers());

  const data = raw ?? [];

  const isEmpty = data.length === 0;

  return (
    <Stack mt={10} mb={25} px={12}>
      <Title label={title} />
      {isFetching ? (
        <LoadingModal open={isFetching} />
      ) : isError ? (
        <ErrorFetching marginTop={544} />
      ) : isEmpty ? (
        <EmptyListData marginTop={544} />
      ) : (
        <Grid container spacing={5} mt={3}>
          {data.map((trainer) => (
            <Grid item xs={6} key={trainer.id} display="grid">
              <TrainerCard data={trainer} />
            </Grid>
          ))}
        </Grid>
      )}
    </Stack>
  );
}

type TrainerCardProps = {
  data: Trainer;
};

export function TrainerCard({ data }: TrainerCardProps) {
  const {
    id,
    trainerProfileData: { avatarUrl, skills },
    profile,
  } = data;
  const navigate = useNavigate();

  const onClick: MouseEventHandler<HTMLDivElement> = () => {
    navigate(id.toString());
  };

  return (
    <Card onClick={onClick}>
      <CardMedia
        component="img"
        image={avatarUrl ?? NoAvatar}
        sx={{ height: 634 }}
      />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100% - 634px)",
          gap: 2,
          pt: "24px !important",
          pb: "48px !important",
        }}
      >
        <Typography variant="h5">{formatProfile(profile, true)}</Typography>
        <Stack direction="row" gap={2}>
          <MilitaryTechIcon sx={{ color: "text.secondary", fontSize: 36 }} />
          <Stack gap={0.5} width="calc(100% - 52px)">
            {skills.slice(0, 2).map((skill) => (
              <Typography
                key={skill}
                component="li"
                variant="body4"
                color="text.secondary"
                noWrap
              >
                {skill}
              </Typography>
            ))}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
