import { Modal, Stack, LinearProgress, Typography, Box } from "@mui/material";
import ReactPlayer from "react-player";
import { createSlice } from "@reduxjs/toolkit";
import { useEffect, useReducer, useRef } from "react";

import Pause from "@/assets/video/pause.png";
import Close from "@/assets/video/close.png";
import ArrowDown from "@/assets/video/arrow-down.png";

import type { PayloadAction } from "@reduxjs/toolkit";

type VideoPlayerModalProps = {
  open: boolean;
  onClose: () => void;
  url: string;
  title: string;
  description: string;
};

const initialState = {
  isPlayVideo: true,
  progress: 0,
  timePlayed: 0,
  remainingTime: 0,
  isShowDetail: false,
};

//  refactor
type ReactPlayerObj = {
  getDuration: () => number;
  getCurrentTime: () => number;
};

const { actions, reducer } = createSlice({
  name: "videoPlayerModal",
  initialState,
  reducers: {
    playVideo(state) {
      state.isPlayVideo = true;
    },
    pauseVideo(state) {
      state.isShowDetail = true;
      state.isPlayVideo = false;
    },
    hideDetail(state) {
      state.isShowDetail = false;
      state.isPlayVideo = true;
    },
    resetVideoPlayer(state) {
      state.isPlayVideo = true;
      state.progress = 0;
      state.timePlayed = 0;
      state.remainingTime = 0;
    },
    updateTimePlay(
      state,
      {
        payload,
      }: PayloadAction<{
        progress: number;
        timePlayed: number;
        remainingTime: number;
      }>
    ) {
      state.progress = payload.progress;
      state.timePlayed = payload.timePlayed;
      state.remainingTime = payload.remainingTime;
    },
    setEndVideo(
      state,
      {
        payload,
      }: PayloadAction<{
        timePlayed: number;
      }>
    ) {
      state.progress = 100;
      state.timePlayed = payload.timePlayed;
      state.remainingTime = 0;
      state.isPlayVideo = false;
    },
  },
});

function useVideoPlayerModal({ onClose }: { onClose: () => void }) {
  const playerRef = useRef(null);
  const [
    { isPlayVideo, progress, isShowDetail, timePlayed, remainingTime },
    dispatch,
  ] = useReducer(reducer, initialState);

  function handlePauseVideo() {
    dispatch(actions.pauseVideo());
  }

  function handleCloseVideo() {
    dispatch(actions.resetVideoPlayer());
    onClose();
  }

  function handleCloseDetail() {
    dispatch(actions.hideDetail());
  }

  function handleEndVideo() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const duration =
      (playerRef.current as unknown as ReactPlayerObj)?.getDuration() || 0;
    dispatch(actions.setEndVideo({ timePlayed: duration }));
  }

  useEffect(() => {
    if (isPlayVideo) {
      const timer = setInterval(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const currentTime =
          (playerRef.current as unknown as ReactPlayerObj)?.getCurrentTime() ||
          0;
        const duration =
          (playerRef.current as unknown as ReactPlayerObj)?.getDuration() || 0;

        const progress = Math.ceil((currentTime / duration) * 100);
        const timePlayed = Math.round(currentTime);
        const remainingTime = Math.round(duration - currentTime);

        dispatch(
          actions.updateTimePlay({ progress, timePlayed, remainingTime })
        );
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isPlayVideo]);

  function convertSecondsToTime(seconds: number) {
    return `${Math.floor(seconds / 60)
      .toString()
      .padStart(2, "0")}:${(seconds % 60).toString().padStart(2, "0")}`;
  }

  return {
    playerRef,
    isPlayVideo,
    progress,
    isShowDetail,
    handleCloseDetail,
    handlePauseVideo,
    handleCloseVideo,
    handleEndVideo,
    timePlayed: convertSecondsToTime(timePlayed),
    remainingTime: convertSecondsToTime(remainingTime),
  };
}

export function VideoPlayerModal({
  open,
  onClose,
  url,
  title,
  description,
}: VideoPlayerModalProps) {
  const {
    playerRef,
    isPlayVideo,
    progress,
    timePlayed,
    remainingTime,
    isShowDetail,
    handlePauseVideo,
    handleCloseDetail,
    handleCloseVideo,
    handleEndVideo,
  } = useVideoPlayerModal({ onClose });

  return (
    <Modal open={open} sx={{ bgcolor: "black" }}>
      <Stack height="100%" justifyContent="center">
        <ReactPlayer
          url={url}
          width="100%"
          height="600px"
          controls={false}
          config={{
            youtube: {
              playerVars: { showinfo: 0, autoplay: 1 },
              embedOptions: {},
            },
          }}
          playing={isPlayVideo}
          onEnded={handleEndVideo}
          ref={playerRef}
        />
        {isShowDetail ? (
          <DetailModal
            handleCloseDetail={handleCloseDetail}
            title={title}
            description={description}
          />
        ) : (
          <ControlModal
            progress={progress}
            timePlayed={timePlayed}
            remainingTime={remainingTime}
            handlePauseVideo={handlePauseVideo}
            handleCloseVideo={handleCloseVideo}
          />
        )}
      </Stack>
    </Modal>
  );
}

type ControlModalProps = {
  progress: number;
  timePlayed: string;
  remainingTime: string;
  handlePauseVideo: () => void;
  handleCloseVideo: () => void;
};

function ControlModal({
  progress,
  timePlayed,
  remainingTime,
  handlePauseVideo,
  handleCloseVideo,
}: ControlModalProps) {
  return (
    <Modal
      open={true}
      componentsProps={{
        backdrop: { style: { backgroundColor: "transparent" } },
      }}
    >
      <>
        <Box
          component="img"
          src={Close}
          height={48}
          position="absolute"
          top={160}
          right={96}
          onClick={handleCloseVideo}
        />
        <Stack
          position="absolute"
          bottom={0}
          width="100%"
          height={240}
          bgcolor="#272D3780"
        >
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ height: "8px" }}
          />
          <Stack direction="row" justifyContent="space-between" px={3} mt={1}>
            <Typography variant="body2" color="white">
              {timePlayed}
            </Typography>
            <Typography variant="body2" color="white">
              {remainingTime}
            </Typography>
          </Stack>
          <Box
            component="img"
            height="80px"
            width="80px"
            src={Pause}
            mt={1}
            alignSelf="center"
            onClick={handlePauseVideo}
          />
        </Stack>
      </>
    </Modal>
  );
}

type DetailModalProps = {
  handleCloseDetail: () => void;
  title: string;
  description: string;
};

function DetailModal({
  handleCloseDetail,
  title,
  description,
}: DetailModalProps) {
  return (
    <Modal
      open={true}
      componentsProps={{
        backdrop: { style: { backgroundColor: "black" } },
      }}
    >
      <>
        <Box
          component="img"
          src={ArrowDown}
          width={48}
          position="absolute"
          top={160}
          right={96}
          onClick={handleCloseDetail}
        />
        <Stack position="absolute" top={232} px={12} gap={4.5} width="100%">
          <Typography variant="h4" color="white">
            {title}
          </Typography>
          <Typography variant="body2" color="white">
            {description}
          </Typography>
        </Stack>
      </>
    </Modal>
  );
}
