import { api } from "@/client/api";
import { configs } from "@/configs";

import type { BannerResponse } from "@/features/authentication/contexts/AuthenticationContext";

export async function getBannerUrls(): Promise<BannerResponse> {
  const response = await api.get<string[]>(
    `${configs.apiUrl}/mirror/fitness-centers/bannerUrls`
  );
  return { bannerUrls: response.data };
}
