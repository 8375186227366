import type { DateTime } from "luxon";

export type Role = {
  id: number;
  name: string;
};

export enum Gender {
  Male = "male",
  Female = "female",
  Unspecified = "unspecified",
}

export enum BloodGroup {
  A = "A",
  AB = "AB",
  B = "B",
  O = "O",
  Unspecified = "unspecified",
}

export enum MaritalStatus {
  Single = "single",
  Marry = "marry",
  Widow = "widow",
  Divorce = "divorce",
  Unspecified = "unspecified",
}

export type UpdateAvatarProfileInput = {
  id: number;
  data: {
    avatar: string;
  };
};

export type Profile = {
  id: number;
  email: string;
  avatarUrl: string | null;
  idCard: string;
  firstName: string;
  lastName: string;
  nickname: string;
  birthDate: Date;
  gender: Gender;
  height: number | null | undefined;
  weight: number | null | undefined;
  maritalStatus: MaritalStatus | null | undefined;
  bloodGroup: BloodGroup | null;
  career: string | null | undefined;
  phoneNo: string;
  emergencyPerson: string | null | undefined;
  emergencyPhoneNo: string | null | undefined;
  emergencyRelation: string | null | undefined;
  address: string | undefined;
  postCode: string | undefined;
  province: string;
  district: string;
  subdistrict: string;
};

export type StaffInput = {
  profile: {
    idCard: string;
    firstName: string;
    lastName: string;
    nickname: string;
    birthDate: Date;
    email: string;
    gender: string;
    phoneNo: string;
    emergencyPerson: string | undefined;
    emergencyPhoneNo: string | undefined;
    emergencyRelation: string | undefined;
    address: string | undefined;
    district: string;
    subdistrict: string;
    province: string;
    postCode: string | undefined;
  };
  roles: number[];
};

export type UpdateStaffInput = {
  id: string;
  data: StaffInput;
};

export type Staff = {
  id: number;
  accountId: string;
  code: string;
  profile: Profile;
  trainerProfile: TrainerProfile;
  trainerProfileData: TrainerProfile;
  roles: Role[];
};

export enum TrainerContract {
  Freelance = "freelance",
  Employee = "employee",
}

export enum Day {
  Friday = "Friday",
  Monday = "Monday",
  Saturday = "Saturday",
  Sunday = "Sunday",
  Thursday = "Thursday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
}

export enum DayShort {
  Friday = "fri",
  Monday = "mon",
  Saturday = "sat",
  Sunday = "sun",
  Thursday = "thu",
  Tuesday = "tue",
  Wednesday = "wed",
}

type DateRange = {
  startTime: DateTime;
  endTime: DateTime;
};

export type WorkingDays = Record<DayString, DateRange>;

export type TrainerProfile = {
  id: number;
  avatarUrl?: string;
  youtubeIntroUrl?: string;
  personalTraining: boolean;
  contract: TrainerContract;
  skills: string[];
  certificates: string[];
  experiences: string[];
  workingDays: WorkingDays;
  deletedAt: Date | null;
};

export type OfficeDayInput = {
  monStartTime: Date | null;
  monEndTime: Date | null;
  tueStartTime: Date | null;
  tueEndTime: Date | null;
  wedStartTime: Date | null;
  wedEndTime: Date | null;
  thuStartTime: Date | null;
  thuEndTime: Date | null;
  friStartTime: Date | null;
  friEndTime: Date | null;
  satStartTime: Date | null;
  satEndTime: Date | null;
  sunStartTime: Date | null;
  sunEndTime: Date | null;
};

export type TrainerInput = {
  avatarUrl: string | null;
  youtubeIntroUrl: string | undefined;
  personalTraining: boolean;
  contract: TrainerContract;
  skills: string[];
  certificates: string[];
  experiences: string[];
  monStartTime: Date | null;
  monEndTime: Date | null;
  tueStartTime: Date | null;
  tueEndTime: Date | null;
  wedStartTime: Date | null;
  wedEndTime: Date | null;
  thuStartTime: Date | null;
  thuEndTime: Date | null;
  friStartTime: Date | null;
  friEndTime: Date | null;
  satStartTime: Date | null;
  satEndTime: Date | null;
  sunStartTime: Date | null;
  sunEndTime: Date | null;
};

export type UpdateTrainerInput = {
  id: string;
  data: TrainerInput;
};

export type Trainer = {
  id: number;
  code: string;
  profile: Profile;
  trainerProfileData: TrainerProfile;
};

export type TimeRange = {
  start: DateTime;
  end: DateTime;
};

export type OfficeDay = {
  enable: boolean;
  day: Day;
  timeRange: TimeRange | null;
};

export type DayString = "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun";

export type Class = {
  id: number;
  name: string;
  youtubeUrl?: string;
  description?: string;
  thumbnailUrls: string[];
  publishedAt?: DateTime;
  numberOfSchedules: number;
  categories: Category[];
  schedules: Schedule[];
};

export type Schedule = {
  id: number;
  location: string;
  description?: string;
  startedAt: DateTime;
  endedAt: DateTime;
  cancelledAt?: DateTime;
  capacity: number;
  joinedAt: DateTime | null;
  cancelBefore: DateTime;
  staffs?: Staff[];
  scheduleTrainers?: {
    staff: Staff;
  }[];
  class: Class | null;
  numberOfBookings: number;
  bookings: Booking[];
  isBooked: boolean;
};

export enum ScheduleStatus {
  Active = "ACTIVE",
  Upcoming = "UPCOMING",
  Completed = "COMPLETE",
  Cancelled = "CANCELLED",
}

export type Booking = {
  id: number;
  cancelledAt: Date | null;
  attendedAt: Date | null;
  member: Member;
  isWaiting: boolean;
  schedule: Schedule;
  createdAt: Date;
};

export enum BookingStatus {
  Booked = "BOOKED",
  Waiting = "WAITING",
  Cancelled = "CANCELLED",
  Absent = "ABSENT",
  Attended = "ATTENDED",
}

export enum BookingType {
  Class = "CLASS",
  PersonalTraining = "PERSONAL_TRAINING",
}

export type Category = {
  id: number;
  name: string;
  description: string;
  thumbnailUrl: string | null;
};

export interface CatergoryData extends Category {
  videoCount?: number;
}

export type CreateCategoryInput = Omit<Category, "id">;

export type UpdateCategoryInput = {
  id: string;
  data: Omit<Category, "id">;
};

export type Video = {
  id: number;
  name: string;
  description: string | null;
  thumbnailUrl: string | null;
  youtubeUrl: string;
};

export type CreateVideoInput = Omit<Video, "id">;
export type UpdateVideoInput = {
  id: string;
  data: Omit<Video, "id">;
};

export type Product = {
  id: number;
  name: string;
  price: number;
  description: string;
};

export type ProductMembership = {
  id: number;
  name: string;
  price: number;
  description: string;
  productMembership: {
    id: number;
    durationIso: string;
  };
};

export type ProductMembershipInput = {
  name: string;
  price: number;
  description: string;
  durationIso: string;
};

export enum QuotaUnit {
  hour = "hour",
  times = "times",
}

export type ProductPT = {
  id: number;
  name: string;
  price: number;
  description: string;
  productPersonalTraining: {
    id: number;
    durationIso: string;
    quota: number;
    quotaUnit: QuotaUnit;
  };
};

export type ProductPTInput = {
  name: string;
  price: number;
  description: string;
  durationIso: string;
  quota: number;
  quotaUnit: QuotaUnit;
};

export enum PersonalTax {
  JuristicPerson = "juristicPerson",
  NormalPerson = "normalPerson",
}

export type MemberInput = {
  profile: Omit<Profile, "id" | "avatarUrl">;
  taxAddress: string | undefined;
  taxPostCode: string | undefined;
  taxProvince: string;
  taxDistrict: string;
  taxSubdistrict: string;
  taxBranch: string | undefined;
  taxName: string | undefined;
  taxNo: string | undefined;
  taxType: PersonalTax | null;
  staffsId: number;
  productMembershipId: number;
  membershipName: string;
  membershipStartedAt: Date;
  membershipEndedAt: Date;
  membershipRemark: string | null;
  membershipPrice: number | undefined;
  membershipDiscountPercentage: number | undefined;
  productPTId: number | null;
  ptName: string | undefined;
  ptStartedAt: Date | null;
  ptEndedAt: Date | null;
  ptRemark: string | null;
  ptPrice: number | undefined;
  ptDiscountPercentage: number | undefined;
};

export type UpdateMemberInput = {
  profile: Omit<Profile, "id" | "avatarUrl">;
  taxAddress: string | undefined;
  taxPostCode: string | undefined;
  taxProvince: string;
  taxDistrict: string;
  taxSubdistrict: string;
  taxBranch: string | undefined;
  taxName: string | undefined;
  taxNo: string | undefined;
  taxType: PersonalTax | null;
};

export enum MemberType {
  Active = "Active",
  Ban = "Ban",
  Expired = "Expired",
}

export type BanMemberInput = {
  id: string;
  data: {
    isBan: boolean;
  };
};

export type VerifyMemberInput = {
  id: number;
  data: {
    code: string;
  };
};

export type Member = {
  id: number;
  code: string | undefined;
  accountId: string | undefined;
  taxAddress: string | undefined;
  taxPostCode: string | undefined;
  taxProvince: string;
  taxDistrict: string;
  taxSubdistrict: string;
  taxBranch: string | undefined;
  taxName: string | undefined;
  taxNo: string | undefined;
  taxType: PersonalTax | null;
  banUntil: Date | null;
  profile: Profile;
  memberships: Membership[];
  membership: Membership | null;
  personalTrainingQuota: PersonalTrainingQuota[];
  personalTraining: PersonalTrainingQuota | null;
};

export enum MemberSort {
  Code = "code",
  Memberships = "memberships",
  PersonalTraining = "personalTraining",
}

export enum MemberSortType {
  Asc = "asc",
  Desc = "desc",
}

export type Membership = {
  id: number;
  startedAt: DateTime;
  endedAt: DateTime;
  remark: string | null;
  product: ProductMembership;
};

export type PurchaseMembershipInput = {
  staffsId: number;
  productMembershipId: number;
  name: string;
  startedAt: Date;
  endedAt: Date;
  remark: string | null;
  discountPercentage: number;
  price: number;
};

export type PurchasePTInput = {
  staffsId: number;
  productPTId: number;
  name: string;
  startedAt: Date;
  endedAt: Date;
  remark: string | null;
  discountPercentage: number;
  price: number;
};

export type PersonalTrainingQuota = {
  id: number;
  startedAt: DateTime;
  endedAt: DateTime;
  usedAt: Date | null;
  remark: string | null;
  product: ProductPT;
  useCount: number;
};

export interface FitnessCenter {
  id: number;
  name: string;
}

export interface StaffBranchRole {
  staffsId: number;
  branchsId: number;
  rolesId: number;
  role: Role;
}

export interface AuthResponsePayload {
  id: number;
  code: string;
  fitnessCentersId: number;
  profilesId: number;
  trainerProfilesId?: number | null;
  fitnessCenter: FitnessCenter;
  roles: Role[];
  profile: Partial<Profile>;
}

export type GetMemberBookingInput = {
  memberId: number;
  type: BookingType;
  query?: string;
};

export type CheckIn = {
  id: number;
  member: Member;
  createdAt: DateTime;
};

export type ReceiptItem = {
  id: number;
  title: string;
  discount: number;
  discountPercent: number;
  amount: number;
};

export type Reciept = {
  id: number;
  subtotal: number;
  discountTotal: number;
  total: number;
  receiptItems: ReceiptItem[];
  createdAt: DateTime;
};

export enum PurchaseType {
  Membership = "MEMBERSHIP",
  ProductPT = "PT",
}

export interface Theme {
  primaryColor: string | null;
  accentColor: string | null;
  textPrimaryColor: string | null;
  textSecondaryColor: string | null;
  textDisabledColor: string | null;
  backgroundColor: string | null;
  paperColor: string | null;
}
