import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { signOut } from "@/utils";

export function SignOutButton() {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (count >= 5) {
        signOut();
      }
      setCount(0);
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [count]);

  return (
    <Button
      disableRipple
      sx={{
        width: "80px",
        height: "80px",
        position: "fixed",
        bottom: 0,
        right: 0,
      }}
      onClick={() => setCount(count + 1)}
    />
  );
}
