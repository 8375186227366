import { Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import { LoadingModal } from "@/components/LoadingModal";
import { ErrorFetching } from "@/components/ErrorFetching";
import { useRequireParams } from "@/utils";
import { getTrainer } from "@/services/trainer";

import { TrainerDetailCard } from "./TrainerDetailCard";

const QUERY_KEY = "getTrainer";

export function TrainerDetailPage() {
  const { id } = useRequireParams(["id"]);

  const {
    data: raw,
    isFetching,
    isError,
  } = useQuery([QUERY_KEY], () => getTrainer(+id));

  return (
    <Stack mt={10} mb={25} gap={8} px={12}>
      {isFetching ? (
        <LoadingModal open={isFetching} />
      ) : isError || !raw ? (
        <ErrorFetching marginTop={560} />
      ) : (
        <TrainerDetailCard data={raw} />
      )}
    </Stack>
  );
}
