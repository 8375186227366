import { useNavigate } from "react-router-dom";

import { LoadingModal } from "@/components/LoadingModal";
import { getClasses } from "@/services/class";
import {
  Stack,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import { EmptyListData } from "@/components/EmptyListData";
import { ErrorFetching } from "@/components/ErrorFetching";
import { Title } from "@/components/Title";
import Thumbnail from "@/assets/thumbnail.png";

import type { Class } from "@/models";
import type { MouseEventHandler } from "react";

export function ClassListPage() {
  const {
    data: raw,
    isFetching,
    isError,
  } = useQuery(["classes"], () => getClasses());
  const data = raw ?? [];

  const title = "คลาสทั้งหมดของเรา";

  const isEmpty = data.length === 0;

  return (
    <Stack px={12} mt={10} mb={25}>
      <Title label={title} />
      {isFetching ? (
        <LoadingModal open={isFetching} />
      ) : isError ? (
        <ErrorFetching marginTop={544} />
      ) : isEmpty ? (
        <EmptyListData marginTop={544} />
      ) : (
        <Grid container spacing={5} mt={8}>
          {data.map((item) => (
            <Grid key={item.id} item xs={6} display="grid">
              <ClassCard data={item} />
            </Grid>
          ))}
        </Grid>
      )}
    </Stack>
  );
}

type ClassCardProps = {
  data: Class;
};

export function ClassCard({ data }: ClassCardProps) {
  const { id, thumbnailUrls, name, categories } = data;
  const navigate = useNavigate();

  const onClick: MouseEventHandler<HTMLDivElement> = () => {
    navigate(id.toString());
  };

  return (
    <Card sx={{ width: 424, height: 427 }} onClick={onClick}>
      <CardMedia
        component="img"
        height={282}
        image={thumbnailUrls[0] ?? Thumbnail}
        alt={name}
      />
      <CardContent>
        <Typography noWrap variant="h5" mt={2}>
          {name}
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontSize: 24 }}
          color="text.disabled"
          mt={1}
        >
          {categories[0].name}
        </Typography>
      </CardContent>
    </Card>
  );
}
